import aboutImage from '@/assets/images/about.png';

export const fqaList = [
    {
        titleRichText: "1. How do you calculate your ACCI Value?",
        contentRichText: `
            <div style="text-align: left; margin-top: 20px;">
                <img src=${aboutImage} alt="">
            </div>
            <article>Cy, the number of citations in the JCR year;</article>
            <article style="margin-top: 25px;">JIFy, the value of journal impact factor;</article>
            <p>y=0, publishing year of the paper; C0, the number of citations in the publishing year; JIF0, the value of journal impact factor in publishing year, then we can get ACCI0;</p>
            <article style="margin-top: 25px;">y=1, the first year after the publishing year of the paper; C1, the number of citations in the first year after the publishing year, JIF1, the value of journal impact factor in the first year after the publishing year, then we can get ACCI1;</article>
            <article style="margin-top: 25px;">y=2, the second year after the publishing year of the paper; C2, the number of citations in the second year after the publishing year, JIF2, the value of journal impact factor in the second year after the publishing year, then we can get ACCI2;</article>
            <article style="margin-top: 25px;">ACCI>0，the paper contributes to journal impact factor；ACCI<0，the paper pulls down the journal impact factor.</article>
        `
    },
    {
        titleRichText: "2. Why do some papers have the badge, while others not?",
        contentRichText: `
            <article>
                Only the paper with a positive contribution factor value is qualified with a badge, and the value is filled in the blank, citations in the brackets. If the paper has a negative ACCI value, the badge will not be assigned and the paper will not be included by the Contrimetirc database.
            </article>
        `
    },
    {
        titleRichText: "3. What is the difference between Altmetric and Contrimetric?",
        contentRichText: `
            <article>
                Altmetric and Contrimetric are both tools for measuring the impact of individual academic papers. Altmetric is a tool for measuring the influence of papers published from a broader perspective, which includes blog, twitter, policy maker and so on in addition to citation. Contrary to Altmetric, the Contrametric is a measure of the relative influence of scientific articles only in terms of the citation and average citation level of the papers, namely JIF. It is a new indicator to measure in a narrower range. The ACCI value of some papers are the most favorable tool for the journal editorial department.
            </article>
            <article style="margin-top: 10px;">
                In order to explore the influence of the author's single article, we will take the periodical as the community to investigate and build a single paper citation contribution measurement index. Journal editors can also judge the impact of an author's previously published articles in their disciplinary community based on their historical citation contribution.
            </article>
        `
    },
    {
        titleRichText: "4. What is the citation performance of overall publications?",
        contentRichText: `
            <article>
                Obviously, if the papers published in the journal generate more citations in the second and third years, it will also play a greater role in the calculation of journal impact factors and the better the image of the journal. It is estimated that only about 23% articles from the journals in JCR Quatile 1 have positive ACCI value and 77% articles have negative effect on their JIF. In another word, a few papers bring "positive contributions" to the impact factors of journals, while most papers bring "negative contributions".
            </article>
            <article style="margin-top: 20px;">
                For example, the positive and negative values of ACCI are the most significant reference values for the measurement of papers. The higher the proportion of papers with positive ACCI, the higher JIF the journal will receive.
            </article>
            <article style="margin-top: 20px;">
                Each paper has two ACCI values, namely CF1 and CF2. The CF1 represents the contribution value of the second year after the publication of the paper, while the CF2 represents the contribution value of the third year after published.
            </article>
        `
    },
    {
        titleRichText: "5. What paper are collected by Contrimetric.com?",
        contentRichText: `
            <article>
                The website contrimetric.com will collect papers with positive CF. If you find your missing paper, you may visit Correct CF and editor will contact you. The contrimetric is no-profit organization and free of charge when you apply for some services.
            </article>
            <article style="margin-top: 20px;">
                The Contrimetric covers publication from 2016-now when all terms meet the requirement.
            </article>
        `
    },
    {
        titleRichText: "6. Publications in Contrimetrics",
        contentRichText: `
            <article>
                <div><a href="https://www.cambridge.org/core/journals/cns-spectrums/article/contrimetric-article-citation-contribution-indicator-for-paper-impact-evaluation/EC9A50C3567FE673582DD3473591EA8B">1. Contrimetric: Article Citation Contribution Indicator for paper impact evaluation</a></div>
                <div style="margin-left:15px"><span style="font-weight:bold">Author: </span> Kristine Kuo</div>
                <div style="margin-left:15px">CNS Spectrums &nbsp; 27 October, 2023</div>
            </article>
            <article style="margin-top: 20px;">
                <div><a href="https://efcc.com.my/efcc-02-2022-81-84/">2. ARTICLE CITATION CONTRIBUTION INDICATOR: APPLICATION IN ENERGY AND ENVIRONMENT</a></div>
                <div style="margin-left:15px"><span style="font-weight:bold">Author: </span> Zhenling Liu</div>
                <div style="margin-left:15px">ECOFEMINISM AND CLIMATE CHANGE &nbsp; 2022</div>
            </article>
        `
    },
    {
        titleRichText: "7. Is Contrimetric·Plugin free?",
        contentRichText: `
            <article>
                Plugin is a completely free extension. It doesn’t require a paid license and doesn’t include any paid features.
            </article>
        `
    },
    {
        titleRichText: "8. How to get started quickly with Contrimetric·Plugin?",
        contentRichText: `
            <article>
                Contrimetric’s <a href="https://www.contrimetric.com/newcase" style="font-weight: bold">Quick start guide</a> will tell you how to create and run your first project and teach you about the product’s core capabilities. For those who are new to the plugin, there is a set of interactive courses that will help you get to grips with the essential functionalities quickly.
            </article>
        `
    },
    {
        titleRichText: "9. How to report feedback/request a feature?",
        contentRichText: `
            <article>
                If you have any ideas on how to improve the plugin, or if you’d like to request a specific feature, please leave your contact information, and someone will reach out to you shortly.
            </article>
        `
    },
    {
        titleRichText: "10. Will Plugin be open source?",
        contentRichText: `
            <article>
                We are considering open sourcing parts of the product or the technology behind it. Stay tuned for details in the coming months.
            </article>
        `
    }
];